import Container from "@mui/material/Container/Container";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { AGENDA_CAROUSEL_SECTION } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const items = [
  "innovance-solutions-agenda-mockups-main.png",
  "innovance-solutions-agenda-mockups-responsive.png",
  "innovance-solutions-agenda-mockups-mode-exploitation.png",
  "innovance-solutions-agenda-mockups-multiple-agendas.png",
  "innovance-solutions-agenda-mockups-vue-mensuelle.png",
  "innovance-solutions-agenda-mockups-tableau-bord-temps-travailles.png",
  "innovance-solutions-agenda-mockups-parametrage-categories.png",
];

const Item = ({ fileName }: { fileName: string }) => {
  const imgSrc = "/images/agenda/" + fileName;

  return (
    <div
      style={{
        width: 1000,
        margin: "auto",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "auto",
          // maxHeight: 450,
        }}
        width={900}
        height={614}
        src={imgSrc}
        alt={fileName}
      />
    </div>
  );
};

const AgendaCarousel = () => {
  return (
    <StyledSectionWrapper sectionId={AGENDA_CAROUSEL_SECTION}>
      <Container className={classes.container}>
        <Carousel
          navButtonsAlwaysVisible
          cycleNavigation
          animation="slide"
          autoPlay={false}
          className={classes.carousel}
          fullHeightHover={false}
          navButtonsProps={{
            className: classes.navButton,
          }}
          navButtonsWrapperProps={{
            className: classes.navWrapper,
          }}
          activeIndicatorIconButtonProps={{
            className: classes.activeIndicator,
          }}
        >
          {items.map((i) => (
            <Item key={i} fileName={i} />
          ))}
        </Carousel>
      </Container>
    </StyledSectionWrapper>
  );
};

export default AgendaCarousel;
