import React from "react";
import Customers from "../components/CustomerSection";
import Seo from "../components/Seo";
import AgendaCarousel from "../views/Agenda/Carousel";
import AgendaFeatures from "../views/Agenda/Features";
import AgendaHero from "../views/Agenda/Hero";
import AgendaMoreToKnow from "../views/Agenda/MoreToKnow";
import AgendaPricings from "../views/Agenda/Pricing";
import { clients } from "../data";

const customers = clients.filter((c) => c.produits.includes("Agenda"));

const AgendaPage = () => {
  return (
    <React.Fragment>
      <Seo title="Innovance Agenda" />
      <AgendaHero />
      <AgendaFeatures />
      <AgendaCarousel />
      <AgendaMoreToKnow />
      <Customers customers={customers} />
      <AgendaPricings />
    </React.Fragment>
  );
};

export default AgendaPage;
