import Alert from "@mui/material/Alert";
import React from "react";
import { AGENDA_PRICING_SECTION } from "../../../util/constants";
import PriceCalculator from "../../../components/PriceCalculator";
import { PriceCalculatorInput } from "../../../components/PriceCalculator/tools";
import PricingSection from "../../../components/PricingSection";
import { AgendaPriceRangeInfos } from "../../../util/types";
import { getAgendaGlobalPrice, getPriceRangeInfos } from "./fp";

const renderDetailedResult = (
  ranges: AgendaPriceRangeInfos[] | null,
  input: PriceCalculatorInput,
  classes: {
    alert: string;
    bold: string;
  },
) => {
  if (!ranges) return null;

  const result = getAgendaGlobalPrice(ranges);

  return (
    <Alert variant="outlined" severity="info" className={classes.alert}>
      Le montant de l'abonnement mensuel Innovance Agenda pour{" "}
      <span className={classes.bold}>
        {input.value} salarié{input.value! > 1 ? "s" : null}
      </span>{" "}
      sera de : <span className={classes.bold}>{result} € HT</span>
      <ul>
        {ranges.map((range) => (
          <li key={`range-from-${range.start}-to-${range.end}`}>
            <p>
              {range.text}{" "}
              {!range.unitPrice
                ? null
                : `(${range.count} salarié${range.count! > 1 ? "s" : ""} x ${
                    range.unitPrice
                  } €) `}
              : <span className={classes.bold}>{range.totalPrice} € HT</span>
            </p>
          </li>
        ))}
      </ul>
    </Alert>
  );
};

const AgendaPricings = () => (
  <PricingSection
    product="Agenda"
    sectionId={AGENDA_PRICING_SECTION}
    priceFileName="innovance-solutions-fiche-tarifs.pdf"
    productFileName="innovance-agenda-fiche-produit.pdf"
    CalculatorComponent={
      <PriceCalculator
        fieldLabel="Nombre de salariés"
        getPriceRangeInfos={getPriceRangeInfos}
        renderDetailedResult={renderDetailedResult}
      />
    }
  />
);

export default AgendaPricings;
