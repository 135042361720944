import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "AgendaCarousel";

export const classes = {
  container: `${PREFIX}-container`,
  carousel: `${PREFIX}-carousel`,
  navButton: `${PREFIX}-navButton`,
  navWrapper: `${PREFIX}-navWrapper`,
  activeIndicator: `${PREFIX}-activeIndicator`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.carousel}`]: {},
  [`& .${classes.navWrapper}`]: {
    [`&:hover .${classes.navButton}`]: {
      backgroundColor: theme.palette.iaBlue.light,
      color: theme.palette.iaBlue.main,
      filter: "none",
      opacity: 1,
    },
  },
  [`& .${classes.navButton}`]: {
    backgroundColor: theme.palette.iaBlue.main,
  },
  [`& .${classes.activeIndicator}`]: {
    color: theme.palette.iaPink.main,
    "&:hover": {
      color: theme.palette.iaPink.light,
    },
  },
}));
