import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import FeatureRow from "../../../components/FeatureRow";
import SectionTitle from "../../../components/layout/SectionTitle";
import {
  AGENDA_FEATURES_SECTION,
  AGENDA_FEATURE_COLLABORATE,
  AGENDA_FEATURE_COMBINE_IB,
  AGENDA_FEATURE_LOAD_PLAN,
  AGENDA_FEATURE_REPORT,
  AGENDA_FEATURE_TIME_MANAGEMENT,
} from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const AgendaFeatures = () => {
  return (
    <StyledSectionWrapper sectionId={AGENDA_FEATURES_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Fonctionnalités" variant="innoGrey" />
        <FeatureRow
          product="IA"
          id={AGENDA_FEATURE_REPORT}
          title="Rendre compte aux financeurs"
          text="Consignez les temps de l’équipe pour sécuriser vos subventions et éditer en quelques clics un compte-rendu d’activité adapté à chacun de vos financeurs"
          imgSrc="/images/agenda/innovance-solutions-agenda-rendre-compte-aux-financeurs.svg"
          imgWidth="811"
          imgHeight="696"
          bullets={[
            "Saisie quotidienne des temps travaillés avec une distinction facilement exploitable entre temps associés aux projets et temps consacrés au fonctionnement de l’association",
            "Arborescence d'intitulés de suivi de temps adaptée à la structuration de vos actions",
            "Champs personnalisés pour accroître encore les possibilités de regroupement ou répondre à la demande d’un financeur particulier (ex. : « workpackage » d’un financement européen)",
            "Analyse et exploitation des temps saisis consolidés au sein du mode exploitation",
            "Export des saisies de l’agenda pour créer des outils de pilotage par projet, par salarié, par convention, etc.",
            "Facilitation de l’édition de votre rapport annuel en consolidant les temps par secteurs d'activités ou par pôles",
            "Transfert de temps entre différents projets et/ou natures de tâches, à l’année ou au mois, pour un ou plusieurs salariés en simultané",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IA"
          id={AGENDA_FEATURE_TIME_MANAGEMENT}
          direction="row-reverse"
          title="Suivre les temps de travail"
          text="Une seule saisie pour le suivi des temps par projets et le suivi légal du temps de travail et des absences"
          imgSrc="/images/agenda/innovance-solutions-agenda-suivre-les-temps-de-travail.svg"
          imgWidth="580"
          imgHeight="564"
          bullets={[
            "Différencier les temps travaillés et non travaillés grâce à des natures de tâches paramétrables selon vos usages (animation, entretien individuel, formation, trajet,… congé payé, RTT, récupération)",
            "Export des saisies dans l’agenda pour créer des outils de suivi des absences et d’éléments utiles pour l’établissement des paies (ex. : tickets restaurant, fiche navette au prestataire,…)",
            "Contrôle visuel rapide du temps de travail ou des absences d’un salarié ou d’une équipe sur une journée, une semaine ou un mois",
            "Clôture et déclôture mensuelle ou annuelle de toutes les saisies de temps",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IA"
          id={AGENDA_FEATURE_LOAD_PLAN}
          title="Piloter un plan de charge"
          text="Un pilotage du plan de charge de l’équipe"
          imgSrc="/images/agenda/innovance-solutions-agenda-piloter-un-plan-de-charge.svg"
          imgWidth="884"
          imgHeight="478"
          bullets={[
            "Tableau de bord pour comparer temps prévisionnels et réalisés saisis dans l’agenda en vue de mettre à jour le plan de charge",
            "Visualiser en temps réel sous forme graphique les temps saisis par les salariés par projet et nature de tâche, les temps travaillés, les absences...",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IA"
          id={AGENDA_FEATURE_COLLABORATE}
          direction="row-reverse"
          title="Collaborer et réduire les contraintes de vos utilisateurs"
          text="Une interface efficiente et conviviale pour collaborer et gagner en autonomie"
          imgSrc="/images/agenda/innovance-solutions-agenda-collaborer-et-reduire-les-contraintes.svg"
          imgWidth="890"
          imgHeight="490"
          bullets={[
            "Copier/Couper-coller d'une ou plusieurs tâches, y compris d’une semaine à une autre",
            "Suppression de plusieurs tâches simultanément",
            "Visualisation en simultané des agendas de plusieurs salariés",
            "Consultation de son agenda et création des tâches depuis un support mobile (téléphone, tablette)",
            "Actions sur l’agenda d’un autre salarié selon la combinaison de droits attribués : « visualisation », « modification », « ajout », « suppression » de tâche",
            "Ajout de participants à une tâche pour faciliter la création de réunions internes, séminaires, formations...",
            "Envoi de notifications par courriel lors d’une action effectuée sur une tâche d’un agenda tiers",
            "Personnalisation de l’affichage de son agenda : orientation, amplitude d’une journée, masquer les week-ends, affichage « 2 semaines », niveau de zoom",
            "Attribution des droits de saisie sur les projets pour chaque salarié et pour chaque exercice",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IA"
          id={AGENDA_FEATURE_COMBINE_IB}
          title="Répartir les charges salariales et indirectes sur les actions"
          text="Une connexion intelligente avec Innovance Budget pour une répartition automatique de la masse salariale et des charges indirectes sur les projets"
          imgSrc="/images/agenda/innovance-solutions-agenda-repartir-salaires-et-charges-indirectes.svg"
          imgWidth="884"
          imgHeight="478"
          bullets={[
            <React.Fragment>
              Couplez l'agenda avec{" "}
              <Link
                component={GatsbyLink}
                to="/budget"
                underline="hover"
                fontWeight="bold"
              >
                Innovance Budget
              </Link>
            </React.Fragment>,
          ]}
        />
      </Container>
    </StyledSectionWrapper>
  );
};

export default AgendaFeatures;
