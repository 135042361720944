import { AGENDA_PRICE_RANGES } from "../../../util/constants";
import { AgendaPriceRangeInfos } from "../../../util/types";

const getRoundedValue = (value: number) => Math.round(value * 100) / 100;

const agendaPriceRangeCalculatorReducer =
  (countKeys: number[]) =>
  (acc: AgendaPriceRangeInfos[], range: AgendaPriceRangeInfos) => {
    /**
     * calcule du nombre de salariés à prendre en compte dans le calcul sur la tranche en cours
     */
    const count = countKeys.slice(range.start, range.end).length;
    if (!count) return acc;

    const rangePrice = !range.unitPrice
      ? range.totalPrice!
      : count * range.unitPrice!;
    const calculatedRange: AgendaPriceRangeInfos = {
      ...range,
      totalPrice: getRoundedValue(rangePrice),
      count,
    };
    acc = [...acc, calculatedRange];
    return acc;
  };

export const getPriceRangeInfos = (count: number) => {
  let result: AgendaPriceRangeInfos[] = [];
  if (count < 6) {
    // ne prendre que la première tranche, soit le forfait de base pour 5 salariés
    const baseRange: AgendaPriceRangeInfos = {
      ...AGENDA_PRICE_RANGES[0],
      count: count,
    };
    result = [baseRange];
    return result;
  }

  const countKeys = Array.from(Array(count).keys());
  return AGENDA_PRICE_RANGES.reduce(
    agendaPriceRangeCalculatorReducer(countKeys),
    result,
  );
};

const agendaGlobalPriceCalculator = (
  acc: number,
  range: AgendaPriceRangeInfos,
) => (acc += range.totalPrice!);
export const getAgendaGlobalPrice = (ranges: AgendaPriceRangeInfos[]) =>
  getRoundedValue(ranges.reduce(agendaGlobalPriceCalculator, 0));
