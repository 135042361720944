import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "AgendaFeatures";

export const classes = {
  container: `${PREFIX}-container`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },
}));
